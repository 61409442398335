<template>
    <div class="reportTkReceInfo defaultReport">
        <div class="filter-box">
            <div class="filter-bnts">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}"  @click="queryType=true"  v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block"  v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block">
                    <div class="flex-box">
                        <div class="lable-txt">券类型:</div>
                        <div class="from-input" style="width: 180px;">
                            <el-select v-model="state" placeholder="全部" multiple collapse-tags @removeTag="()=>state=[]">
                                <el-option :value="1" label="线下现金券"></el-option>
                                <el-option :value="2" label="线下商品券"></el-option>
                                <el-option :value="5" label="抖音平台券"></el-option>
                                <el-option :value="4" label="美团平台券"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">券名称:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="tkIds" placeholder="请选择" :defaultInputTxt="defaultTKTxt" :data="treePosTickets" title="券名称选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="bnt-search" @click="GetCouponReceive()" v-if="tagType==1">查询</div>
                <div class="inline-block" v-else>
                    <div class="flex-box">
                        <div class="from-input search-input"><input type="text" v-model="searchText"  placeholder="台号、结账单号模糊检索" /></div>
                        <div class="search-flex">
                            <div class="bnt-search" @click="GetCouponReceiveDetails()" >查询</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-bnts select">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="nav-box-top">
                <div class="nav-box" v-mouse-scroll >
                    <div class="nav-li" :class="{selected:tagType==1}" @click="tagType=1">券核销统计表</div>
                    <div class="nav-li" :class="{selected:tagType==2}" @click="tagType=2">券核销明细表</div>
                </div>
            </div>
            <div class="tab-pane" v-show="tagType==1">
                <div class="table-box grey-table isFooter" ref="tableBox"   >
                    <el-table class="el-table--scrollable-y"  :data="tableListPage"  border ref="elTable" 
                        @sort-change="sortChange" 
                        show-summary
                        :summary-method="getSummaries"
                        @cell-dblclick="orderDetail"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column fixed label="序号" width="50" type="index">
                            <template #default="scope">{{scope.$index+1+(pagesize*(currentPage-1))}}</template>
                        </el-table-column>
                        <el-table-column sortable prop="PaymentName" label="券对应支付方式" min-width="120" align="left" ></el-table-column>
                        <el-table-column sortable prop="CouponName" label="券名称" min-width="100" align="left" >
                            <template #default="scope">
                                <span class="link">{{scope.row.CouponName}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="CouponFaceValue" label="面值" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponSalePrice" label="销售单价" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="ReceiveNum" label="使用张数" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponPayMoney" label="抵扣总额" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponPayFactMoney" label="实收总额" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="tableFilter" 
                    :default-index="currentPage" 
                    @current-change="(index)=>currentPage=index"
                    @page-change="(list)=>tableListPage=list" 
                    :page-size="pagesize"></table-page-btn>
            </div>
            <div class="tab-pane" v-show="tagType==2">
                <div class="table-box grey-table isFooter" ref="tableBox2"   >
                    <el-table class="el-table--scrollable-y"  :data="tableListPage2"  border ref="elTable2" 
                        @sort-change="sortChange2" 
                        show-summary
                        :summary-method="getSummaries2"
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                        <el-table-column fixed label="序号" width="50" type="index">
                            <template #default="scope">{{scope.$index+1+(pagesize2*(currentPage2-1))}}</template>
                        </el-table-column>
                        <el-table-column sortable prop="Eat_CheckIndex" label="结帐单号" min-width="120" align="left" ></el-table-column>
                        <el-table-column sortable prop="Eat_DeskName" label="台号" min-width="100" align="left" > </el-table-column>
                        <el-table-column sortable prop="PaymentName" label="券对应支付方式" min-width="120" align="left" ></el-table-column>
                        <el-table-column sortable prop="CouponName" label="券名称" min-width="100" align="left" ></el-table-column>
                        <el-table-column sortable prop="CouponFaceValue" label="面值" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponSalePrice" label="销售单价" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="ReceiveNum" label="使用张数" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponPayMoney" label="抵扣总额" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="CouponPayFactMoney" label="实收总额" min-width="80" align="right" :formatter="priceFormat" data-format="number"></el-table-column>
                        <el-table-column sortable prop="Platform_CouponName" label="平台券名称" min-width="100" align="left" ></el-table-column>
                        <el-table-column sortable prop="Platform_CouponCode" label="平台券编码" min-width="100" align="left" ></el-table-column>
                        <el-table-column sortable prop="Operator_Name" label="操作员"  min-width="100" align="left"></el-table-column>
                        <el-table-column sortable prop="Operate_Time" label="操作时间"  min-width="120" :formatter="dateFormat"></el-table-column>
                    </el-table>
                </div>
                <table-page-btn class="fy" :data="table2Filter" 
                    :default-index="currentPage2" 
                    @current-change="(index)=>currentPage2=index"
                    @page-change="(list)=>tableListPage2=list" 
                    :page-size="pagesize2"></table-page-btn>
                </div>
        </div>
     </div>
</template>
<script>


/**券核销统计表 */
export default {
    name:'reportTkReceInfo',
    data(){
        return {
            //页签
            tagType:1,
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            //排序用
            sortable:null,
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            /**券类型 1-全部 2-线下现金券 3-线下商品券 4-美团平台券 5-抖音平台*/
            state:[],
            /**票券数据 */
            posTickets:[],
            //券id 多个
            tkIds:[],
            //券名称
            defaultTKTxt:"",
            tempParam:null,
            //台号、结账单号模糊检索
            searchText:'',
            /**表格数据 明细数据 */ 
            currentPage2:1,
            tableList2:[],
            tableListPage2:[],
            pagesize2:9,//每页的数据条数
            //排序用
            sortable2:null
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.nowadays();
        //票券数据结构
        this.$cacheData.PosTickets().then((d)=>{
            console.log(d)
            this.posTickets=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到票券数据', "提示", {confirmButtonText: "确定"});
        })
    },
    watch:{
    },
    computed:{
         //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //合计
        total(){
            let total={
                PaymentName:(this.tableList||[]).length+"条记录",
                ReceiveNum:0,
                CouponPayFactMoney:0,
                CouponPayMoney:0,
            };
            (this.tableList||[]).forEach(it=>{
                total.ReceiveNum+=it.ReceiveNum;
                total.CouponPayFactMoney+=it.CouponPayFactMoney;
                total.CouponPayMoney+=it.CouponPayMoney;
            })
            return total;
        },
        //合计
        total2(){
            let total={
                ReceiveNum:0,
                CouponPayFactMoney:0,
                CouponPayMoney:0,
            };
            (this.tableList2||[]).forEach(it=>{
                total.ReceiveNum+=it.ReceiveNum;
                total.CouponPayFactMoney+=it.CouponPayFactMoney;
                total.CouponPayMoney+=it.CouponPayMoney;
            })
            return total;
        },
        //券树形结果数据
        treePosTickets(){
            let data=[]
            if(this.posTickets?.length>0){
                for(let kind=1;kind<4;kind++){
                    let json={
                        id:kind,
                        name:({1:"现金券",2:"无面值商品券",3:"有面值商品券"})[kind],
                        children:this.posTickets?.filter(it=>it.KIND==kind).map(it=>{
                            return {
                                id:it.TK_AUTOID,
                                name:it.TK_NAME
                            }
                        }),
                    }
                    if(json.children?.length>0){
                        data.push(json);
                    }
                }
               
            }
            return [{
                id:1,
                name:"全选",
                children:data
            }];
        },
        /**过滤数据 */
        tableFilter(){
            let list=this.tableList;
            //排序
            if(this.sortable){
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }
            return list;
        },
        /**明细数据 条件筛选 */
        table2Filter(){
            let list=this.tableList2;
            if((this.searchText||"").trim()){
                let text=(this.searchText||"").trim().toUpperCase();
                list=list.filter(it=>(it.Eat_CheckIndex||"").toUpperCase().indexOf(text)>=0 || (it.Eat_DeskName||"").toUpperCase().indexOf(text)>=0);
            }
            //排序
            if(this.sortable2){
                let sorttype=this.sortable2.order=='ascending'?'asc':'desc';
                list.order(this.sortable2.prop,sorttype);
            }
            return list;
        }
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**数据排序 */
        sortChange2(column){
            this.sortable2=column;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize2(){
            if(this.$refs.tableBox2){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox2.clientHeight - 80;
                    this.pagesize2=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            if(this.tagType==1){
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable,
                    titleName:"券核销统计表",
                    list:this.tableFilter,
                })
            }else{
                this.$excelCommon.elTableToExcel({
                    elTable:this.$refs.elTable2,
                    titleName:"券核销明细表",
                    list:this.table2Filter,
                })
            }
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm');
        },
        /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        getSummaries({ columns, data }){
            const sums= [];
            columns.forEach((column, index) => {
                if(this.total[column.property]!=undefined){
                    if(column.property=="PaymentName"){
                        sums[index]=this.total[column.property];
                    }else{
                        sums[index]=this.priceFormat(null,null,this.total[column.property]);
                    }
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        getSummaries2({ columns, data }){
            const sums= [];
            columns.forEach((column, index) => {
                if(this.total2[column.property]!=undefined){
                    sums[index]=this.priceFormat(null,null,this.total2[column.property]);
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询 */
        GetCouponReceive(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Coupon_Types:this.state,//券类型，为空或0：全部类型，1：线下现金券，2：线下商品券，3：抖音平台券，4：美团平台券
                Coupon_AutoIds:this.tkIds, //券ID
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetCouponReceiveRecords",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tempParam=param;
                    this.tempParam.businessHours=this.businessHours;
                    this.tableList=d.ResponseBody;
                    console.log(d)
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        /**查询 明细 */
        GetCouponReceiveDetails(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Coupon_Types:this.state,//券类型，为空或0：全部类型，1：线下现金券，2：线下商品券，3：抖音平台券，4：美团平台券
                Coupon_AutoIds:this.tkIds, //券ID
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.GetCouponReceiveDetails",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList2=d.ResponseBody;
                    console.log(d)
                    this.setTablePageSize2();
                    this.currentPage2=1;
                }else{
                    this.tableList2=[];
                    this.currentPage2=1;
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList2=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="CouponName"){//穿透 券核销明细表
                this.tagType=2;
                this.tkIds=[row.CouponAutoID];//券ID
                this.defaultTKTxt=row.CouponName;
                this.searchText="";
                this.GetCouponReceiveDetails();
                // this.$emit("addFrame",{
                //     key:"reportTkReceDetail",
                //     data:{
                //         businessHours:this.tempParam.businessHours,//是否日期范围查询  true: 日期范围    false：当前营业数据
                //         queryType:this.tempParam.DateRange_YN,
                //         Coupon_Types:this.tempParam.Coupon_Types,//券类型，为空或0：全部类型，1：线下现金券，2：线下商品券，3：抖音平台券，4：美团平台券
                //         Coupon_AutoIds:[row.CouponAutoID], //券ID
                //     },
                //     title:'券核销明细表',
                //     url:'reportTkReceDetail'
                // });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportTkReceInfo.scss'
</style>